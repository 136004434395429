import React from "react";
import Tab from "./parts/tab";
import ConnectButton from "./parts/connectButton";
import LanguageTab from "./parts/languageTab";
import MenuBurger from "../../assets/SVG/header/HambarNew.svg";
import { useDispatch, useSelector } from "react-redux";
import {mobileHeader} from "../../store/reducers/homePageSlice"
import "./header.css";

export default function Header() {

  const tabsName = [
    {link: 'https://bridge.xp.network/', tabName: 'Bridge'},
    {link: 'https://blog.xp.network/', tabName: 'Blog'},
    {link:'https://stake.xp.network/', tabName: 'Staking'} ]

  const dispatch = useDispatch()
  const openHeaderMobile = () => dispatch(mobileHeader())
  return (
    <>
      <header className="header-container">
        <img src={MenuBurger} alt="MenuBurger" className="menu-burger-header" onClick={openHeaderMobile}/>
        <div className="tabs-container desktop">
          {tabsName.map((n,i) => (
            <Tab {...n} key={`link-number-${i}`} />
          ))}
          <LanguageTab/>
          <ConnectButton />
        </div>

        <label className="xpnet-giveaway-style">$XPNET GIVEAWAY</label>
      </header>
    </>
  );
}
