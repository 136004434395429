import { configureStore } from "@reduxjs/toolkit";
import homePageSlice from "./reducers/homePageSlice";

const store = configureStore({
  reducer: {
    homePage: homePageSlice.reducer,
  },
});

export default store;
