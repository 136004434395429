import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

const backend = "https://xpgiveaway.herokuapp.com"

export const lastImage = createAsyncThunk("file/lastimage", async () => {
  let FullRunUrl = `${backend}/file/lastimage`;
  const data = await axios.get(FullRunUrl);
  return { ...data.data };
});


export const getWinner = createAsyncThunk("win/username", async () => {
  let winnerURL = `${backend}/win/username`;
  const data = await axios.get(winnerURL);
  return { ...data.data };
});

export const getUsers = createAsyncThunk("users/retweet", async () => {
  let winnerURL = `${backend}/users/retweet`;
  const data = await axios.get(winnerURL);
  return { ...data.data };
});


const initialState = {
image: '',
winner: '',
timeCount: '',
mobileHeaderStatus: false, 
mobileParticipating: false,
desktopParticipatingPopup: false,
usersToShow: []
};

const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    mobileHeader(state,action){
      state.mobileHeaderStatus = !state.mobileHeaderStatus
    },
    mobileParticipatingStatus(state,action){
      state.mobileParticipating = !state.mobileParticipating
    },
    desktopParticipating(state,action){
      state.desktopParticipatingPopup = !state.desktopParticipatingPopup;
      console.log(state.desktopParticipatingPopup);
    }
  },
  extraReducers: {
    [lastImage.pending]: (state, action) => {
      state.loading = true;
    },
    [lastImage.fulfilled]: (state, action) => {
      state.image = action.payload.img;
      if(state.image?.imageTimeEnd){
      let time = moment(state.image.imageTimeEnd).format("MMM D, YYYY HH:mm:ss");
      state.timeCount  =  new Date(time.toString()).getTime()
      }else{
        console.log("Time error");
      }
      state.loading = false;
    },
    [lastImage.rejected]: (state, action) => {
      state.loading = false;
    },
    [getWinner.pending]: (state, action) => {
      state.loading = true;
    },
    [getWinner.fulfilled]: (state, action) => {
      const {userWin} = action.payload
      if(userWin){
        state.winner = userWin.userName;
       }else{
        state.winner = ''
      }
      state.loading = false;
    },
    [getWinner.rejected]: (state, action) => {
      state.loading = false;
    },
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      const {users} = action.payload
      if(users){
      action.payload.users.forEach(n => {
        state.usersToShow.push("@"+ n.userName)
      });
    }else{
      console.log("not users");
    }
      // state.winner = action.payload.userWin.userName;
      state.loading = false;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {mobileHeader,mobileParticipatingStatus,desktopParticipating} = homePageSlice.actions;

export default homePageSlice;
