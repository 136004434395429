import React from "react";
import "./footer.css";
import XP from "../../assets/SVG/footer/xpPower.svg";
import Linkedin from "../../assets/SVG/footer/linkedinIcon.svg";
import Reddit from "../../assets/SVG/footer/telegramIcon.svg";
import Twitter from "../../assets/SVG/footer/twitterIcon.svg";
import Telegram from "../../assets/SVG/footer/telegram icon.svg";
export default function Footer() {
  return (
    <>
      <div className="footer-continer">
        <div className="footer-border"></div>
        <div className="footer-flex">
          <div className="footer-flex-xp">
            <div className="flex-terms">
            <div className="footer-text">Terms</div>
            <div className="footer-text">Privacy Policy</div>
            </div>
            <a target="_blank" rel="noopener noreferrer" href="https://xp.network/">  <img src={XP} alt="XP" className="xp-svg" /></a>
          </div>
          <div className="footer-social">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/xp-network"><img src={Linkedin} alt="linkedin" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/user/XP_network/"><img src={Reddit} alt="Reddit" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/xpnetwork_"> <img src={Twitter} alt="Twitter" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://t.me/xp_network"><img src={Telegram} alt="Telegram" /></a>

          </div>
        </div>
      </div>
    </>
  );
}
