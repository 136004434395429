import React from "react";

export default function ParticipatingUserName(props) {
  const { userName } = props;
  return (
    <>
      <div className="Participating-User-Name-text">{userName}</div>
      <div className="Participating-User-Name-text-border-mobile"></div>
    </>
  );
}
