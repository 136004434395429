import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShapesBehindCardDesktop from "../../../assets/img/shapesBehindCardDesktop.png";
import ShapesCardMobile from "../../../assets/img/shapesCardMobile.png";
import PurpleBubbleMobile from "../../../assets/img/vividBubble.png";

export default function PriceSection() {
  const { image,winner } = useSelector((s) => s.homePage);

  return (
    <>
      <section className="price-section-container">
        <div className="price-section-price-image">
          {}
          <img src={image ? image.imageBase64 : ''} alt="giveawayImage" />
        </div>
        <div className="price-section-winner">
          <img
            src={ShapesBehindCardDesktop}
            alt="ShapesBehindCardDesktop"
            className="shapes-behind-card-desktop"
          />
          <img
            src={ShapesCardMobile}
            alt="ShapesCardMobile"
            className="shapes-card-mobile"
          />
          <img
            src={PurpleBubbleMobile}
            alt="PurpleBubbleMobile"
            className="purple-bubble-mobile"
          />
          <div>
            <div className="aaa">
              <div className="price-section-winner-text">
                The{" "}
                <span className="price-section-winner-text-give-away">
                  Giveaway
                </span>
                <div>Winner</div>
              </div>
              <div className="price-section-winner-is">
                <div>{winner === "None" ? '' :  "@"+winner}</div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </section>
    </>
  );
}
