import React, { useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchGlass from "../../../../assets/SVG/Participating/searchInput.svg";
import ClosePopupButton from "../../../../assets/SVG/desktopPop/closeDesktop.svg";
import SmallTitleStar from "../../../../assets/SVG/desktopPop/smallStarTitle.svg";
import MidStarTopGrid from "../../../../assets/SVG/desktopPop/midStarTopGrid.svg"
import { desktopParticipating } from "../../../../store/reducers/homePageSlice";
import { DetectOutsideClick } from "../../../../hook/closeHook";

export default function ParticipantsPopup() {
  const dispatch = useDispatch();
  const { desktopParticipatingPopup, usersToShow } = useSelector(
    (s) => s.homePage
  );
  const [search, setSearch] = useState("");
  const  ref = useRef(null)
  const searchUser = (e) => {
    const { value } = e.target;
    setSearch(value);
  };
  const close = () => dispatch(desktopParticipating());
  DetectOutsideClick(ref, () => setTimeout(() => close(), 100)); // close menu

  const usersShow = usersToShow
    ? usersToShow
        .map((n, i) => n)
        .filter((n) => n.toLowerCase().includes(search.toLowerCase()))
    : "";
  return (
    <>
      <div 
        className={desktopParticipatingPopup ? "overlay-none" : ' "overlay"'}
      >

        <div className={desktopParticipatingPopup ? "popup" : "pop-hide"} >
          <div className="content" >
            <div className="close-popup-button" onClick={close} >
              <img src={ClosePopupButton} alt="close-popup" />
            </div>
            <h1 className="popup-desktop-title">
              All Participants <img src={SmallTitleStar} alt="SmallTitleStar" />
            </h1>
            <div className="search-popup-desktop">
              <img src={SearchGlass} alt="search-desktop" />
              <img src={MidStarTopGrid} alt='midstar' className="mid-grid-star"/>

              <input onChange={searchUser} value={search} placeholder="Your Name"/>
            </div>
            <div className="popup-users">
            <div className="popup-users-bg" id="nice-scroll-users" >
              {usersShow.map((n) => (
                <div className="pop-up-users">{n}</div>
              ))}
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
