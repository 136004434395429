import React,{useEffect,useRef} from "react";
import TimeContainer from "../../Components/TimeContainer";
import PriceSection from "./PriceSection/index";
import Participating from "./Participating/Participating";
import SmallWhiteStar from "../../assets/SVG/Participating/smallWhiteStar.svg";
import { useDispatch, useSelector } from "react-redux";
import {lastImage,getWinner,getUsers,mobileHeader} from "../../store/reducers/homePageSlice";
import {DetectOutsideClick} from "../../hook/closeHook"
import Tab from "../../Components/Header/parts/tab";
import CloseMenu from "../../assets/SVG/header/closePopup.svg";
import "./home.css";

export default function HomePage() {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const {mobileHeaderStatus} = useSelector(s=>s.homePage)
  useEffect(()=>{
    dispatch(lastImage());
    dispatch(getWinner());
    dispatch(getUsers());
  },[])

  DetectOutsideClick(ref, () => setTimeout(() => closeMobileHeader(), 100)); // close menu

const closeMobileHeader = () => dispatch(mobileHeader())

    const tabsName = [
      {link: 'https://bridge.xp.network/', tabName: 'Bridge'},
      {link: 'https://blog.xp.network/', tabName: 'Blog'},
      {link: 'https://stake.xp.network/', tabName: 'Staking'},
    ]
  return (
    <>
      <div>
     { mobileHeaderStatus ? <div className="mobile-header" ref={ref}>
     <img src={CloseMenu} alt="close"  className="close-mobile-popup" onClick={closeMobileHeader}/>

        {tabsName.map((n) => (
            <Tab {...n} />
          ))}
        </div>
: ''}
        <div className="home-page-time-center">
          <TimeContainer />
        </div>

        <PriceSection />
        <div className="Participating-title-style mobile-Participating-title">
        <img src={SmallWhiteStar} alt="SmallWhiteStar" className="small-white-star"/>

          <div>Thank You For</div>
          <div>Participating</div>
          
        </div>
        <div className="home-page-time-center">
          <Participating />
        </div>
        
      </div>
    </>
  );
}
