import React from "react";

export default function tab(props) {
  const { tabName,link } = props;
  return (
    <>
      <div className="tab-container">
        <a target="_blank" rel="noopener noreferrer" href={link}>{tabName}</a>
      </div>
    </>
  );
}
