import React,{useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import SmallStar from "../../../assets/SVG/Participating/smallStar.svg";
import BigStar from "../../../assets/SVG/Participating/bigStar.svg";
import SearchSvg from "../../../assets/SVG/Participating/searchInput.svg";
import ParticipatingUserName from "./parts/ParticipatinguserName";
import BehindUsers from "../../../assets/SVG/price/behindUsers.svg";
import {mobileParticipatingStatus,desktopParticipating} from "../../../store/reducers/homePageSlice";

export default function Participating() {
const dispatch = useDispatch()
  const openMobileParticipating = () => dispatch(mobileParticipatingStatus()) 
  const openDesktopPopup = () => dispatch(desktopParticipating())
  const [search,setSearch] = useState('');
  const {usersToShow} = useSelector(s=>s.homePage)
  const searchUser = (e) => {
    const {value} = e.target;
    setSearch(value)
  }
const usersShow = usersToShow
? usersToShow
    .map((n,i) => n).filter(n => n.toLowerCase().includes(search.toLowerCase())): ""
  return (
    <>
      <div className="Participating">
        <div className="Participating-container">
          <img src={SmallStar} alt="smallStar" className="small-star" />
          <img src={BigStar} alt="bigStar" className="big-star" />
          <img src={BehindUsers} alt="BehindUsers" className="behind-users" />
          <div className="Participating-inner-container">
            <div className="Participating-header">
              <div className="Participating-title-style">
                Thank You For Participating
              </div>

              <div className="search-Participating">
                <img src={SearchSvg} alt="search-svg" />
                <input
                  className="search-Participating-input"
                  placeholder="Your Name"
                  onChange={searchUser}
                  value={search}
                />
              </div>
            </div>
            <div className="Participating-users">
              {usersShow ? usersShow.slice(0, 10).map((n,i) => <ParticipatingUserName userName={n} key={`participating-user-name-desktop-${i}`}/>): ''}
            </div>
          </div>
        </div>
        <div className="view-all-Participating-desktop">
          <div onClick={openDesktopPopup}>see all participants</div>
        </div>
        <div className="view-all-Participating-text">
         <div onClick={openMobileParticipating}>View All</div> 
          </div>
      </div>
    </>
  );
}
