import React, { useRef } from "react";
import UserRow from "./parts/UserRow";
import CloseSvg from "../../assets/SVG/mobileParticipating/CloseSlider.svg";
import { useDispatch, useSelector } from "react-redux";
import { mobileParticipatingStatus } from "../../store/reducers/homePageSlice";
import { DetectOutsideClick } from "../../hook/closeHook";

import "./mobileParticipating.css";
export default function MobileParticipating() {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { usersToShow } = useSelector((s) => s.homePage);
  const close = () => dispatch(mobileParticipatingStatus());
  DetectOutsideClick(ref, () => setTimeout(() => close(), 100)); // close menu

  let users = usersToShow
    ? usersToShow.map((n, i) => <UserRow name={n} key={`user-mobile-${i}`} />)
    : "";
  return (
    <>
      <div className="mobile-participating-container" ref={ref}>
        <img
          src={CloseSvg}
          className="close-slider"
          onClick={close}
          alt="close"
        />
        <div className="mobile-participating-section" id="nice-scroll">
          {users}
        </div>
      </div>
    </>
  );
}
