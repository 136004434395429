import "./App.css";
import Header from "./Components/Header";
import HomePage from "./Pages/Home";
import Footer from "./Components/Footer/index";
import MobileParticipating from "./Components/mobileParticipating/index";
import ParticipantsPopup from "./Pages/Home/Participating/parts/participantsPopup"
import { useSelector } from "react-redux";

function App() {
  const {mobileParticipating} = useSelector(s=>s.homePage)
  return (
    <div className="App" id="app-nice-scroll">
        <Header />
      <div className="wrapper" >
        <HomePage />
      </div>
      <ParticipantsPopup/>
     {mobileParticipating ? <MobileParticipating/> : ''} 
        <Footer />
    </div>
  );
}

export default App;
