import React from "react";
import USflag from "../../../assets/SVG/language/Eng.svg"
export default function LanguageTab() {
  const language = {};
  return (
    <>
      <div className="tab-container" style={{display: "none"}}>
        <div className="language-text"><img src={USflag} alt="usFlag"/> EN</div>
      </div>
    </>
  );
}
