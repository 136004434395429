import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./timeContainer.css";

export default function TimeContainer() {
  const {timeCount } = useSelector((s) => s.homePage);

  const [day, setDay] = useState("00");
  const [min, setMin] = useState("00");
  const [hours, setHours] = useState("00");
  const [seconds, setSeconds] = useState("00");

  // Set the date we're counting down to
  let x = "";
  useEffect(() => {
    countDown();
    return () => clearInterval(x);
  }, [timeCount]);

  const countDown = () => {
    x = setInterval(() => {
      var now = new Date();
      var distance = timeCount - now;

  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (seconds <= 9) {
        setSeconds("0" + seconds);
      } else {
        setSeconds(seconds);
      }

      if (days <= 9) {
        setDay("0" + days);
      } else {
        setDay(days);
      }
      if (hours <= 9) {
        setHours("0" + hours);
      } else {
        setHours(hours);
      }
      if (minutes <= 9) {
        setMin("0" + minutes);
      } else {
        setMin(minutes);
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setMin("00")
        setHours("00")
        setDay("00")
        setSeconds("00")
      }
    }, 1000);
  };
  // Update the count down every 1 second

  return (
    <>
      <div className="time-container">{day + ":" + hours + ":" + min + ":" + seconds}</div>
    </>
  );
}
